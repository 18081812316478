<div class="fee-graph" *ngIf="tx && estimate">
  <div class="column">
    <ng-container *ngFor="let bar of bars">
      <div class="bar {{ bar.class }}" [class.active]="bar.active" [style]="bar.style" (click)="onClick($event, bar);">
        <div class="fill"></div>
        <div class="line">
          <p class="fee-rate">
            <span class="label">{{ bar.label }}</span>
            <span class="rate">
              <app-fee-rate [fee]="bar.rate"></app-fee-rate>
            </span>
          </p>
        </div>
        <div class="spacer"></div>
        <span class="fee">{{ bar.class === 'tx' ? '' : '+' }} {{ bar.fee | number }} <span class="symbol" i18n="shared.sat|sat">sat</span></span>
        <div class="spacer"></div>
        <div class="spacer"></div>
      </div>
    </ng-container>
  </div>
</div>
